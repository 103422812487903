body {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 19px;
}

h1 {
    font-size: 28px;
}

h1,
p {
    text-align: center;
}

table.sudoku {
    border: 3px solid #008a73;
    border-collapse: collapse;
    margin: 20px auto;
}

td {
    height: 2em;
    width: 2em;
    border: 1px solid #ccc;
    text-align: center;
    outline: none;
}

td input {
    font-size: 1em;
    width: 1em;
    border: none;
    text-align: center;
    outline: none;
}

td:nth-child(3n) {
    border-right: 3px solid #008a73;
}

tr:nth-child(3n) {
    border-bottom: 3px solid #008a73;
}

.buttons {
    text-align: center;
}

button {
    color: #3587b1;
    border: none;
    font-size: 1em;
    padding: 10px;
    margin: 0px 5px;
    background-color: white;
    display: inline-block;
    border: 2px solid #3587b1;
    transition-property: all;
    transition-duration: 0.5s;
    cursor: pointer;
    outline: none;
}

button:hover {
    color: white;
    background-color: #3587b1;
}
